<template>
  <div class="sns">
    <div class="hero">
      <img class="hero__img" :src="sunFlower" alt="ひまわり" />
    </div>
    <div class="links">
      <div class="links__area">
        <div class="links__area__item">
          <a
            class="link"
            target="_blank"
            href="https://canaito0727.thebase.in/"
          >
            <img class="icon" :src="heart" alt="instagram icon" />
            shop
          </a>
        </div>
        <div class="divider"></div>
        <div class="links__area__item">
          <a
            class="link"
            target="_blank"
            href="https://www.instagram.com/cana.ito/"
          >
            <img class="icon" :src="instagram" alt="instagram icon" />
            instagram
          </a>
        </div>
        <div class="links__area__item">
          <a class="link" target="_blank" href="https://twitter.com/cana_ito">
            <img class="icon" :src="twitter" alt="twitter icon" />
            twitter
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      heart: require("@/assets/sns/heart.png"),
      instagram: require("@/assets/sns/instagram.png"),
      twitter: require("@/assets/sns/twitter.png"),
      sunFlower: require("@/assets/sns/sun-flower.png"),
    };
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .sns {
    position: relative;
    background-color: #06dafa;
    color: #1d4d83;
    height: 100vh;
    width: calc(100% - 256px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* contents */
  .links {
  }
  .links__area {
  }
  .links__area__item {
    margin: 24px 0 0 80px;
  }
  .links__area__item:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
  .divider {
    height: 2px;
    width: 320px;
    border-radius: 24px;
    background-color: #204984;
    margin: 40px 0 40px 96px;
  }

  .icon {
    width: 40px;
    transform: translateY(1px);
  }

  .link {
    font-size: 48px;
    font-family: futura;
    color: #204984;
    font-weight: 900;
    letter-spacing: -1px;
    text-transform: uppercase;
    transition: 0.2s;
  }
  .link:hover {
    opacity: 0.7;
  }
}
/* SP */
@media (max-width: 1070px) {
  .sns {
    position: relative;
    background-color: #06dafa;
    color: #1d4d83;
    height: 110vh;
  }

  .hero {
    text-align: center;
  }
  .hero__img {
    margin: 88px 0 24px 0;
    height: 320px;
    transform: translateX(-8px);
  }

  /* contents */
  .links {
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .links__area {
  }
  .links__area__item {
    margin: 24px 0;
  }
  .icon {
    width: 32px;
    transform: translateY(2px);
  }
  .divider {
    height: 2px;
    width: 240px;
    border-radius: 24px;
    background-color: #204984;
    margin: 24px auto 0 auto;
  }

  .link {
    color: #1d4d83;
    font-size: 32px;
    font-family: futura;
    font-weight: 900;
    letter-spacing: -1px;
    text-transform: uppercase;
    transition: 0.2s;
  }
  .link:hover {
    opacity: 0.7;
  }
}
</style>
