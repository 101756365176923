<template>
  <div class="event">
    <div class="event__hero">
      <img
        class="event__hero__img"
        :src="isMobile ? heroSP : heroPC"
        alt="event img"
      />
    </div>
    <div class="event__content">
      <ul class="event__content__area">
        <template v-for="(event, index) in reverseEvent" :key="event.id">
          <li class="event__content__area__item">
            <router-link class="router-link" :to="`/event/${index}`">
              <img class="icon" :src="heart" alt="icon" />
              {{ event.title }}
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      heroSP: require("@/assets/event/hero_sp.png"),
      heroPC: require("@/assets/event/hero_pc.png"),
      heart: require("@/assets/event/heart.png"),
      events: this.$store.state.eventContents,
    };
  },
  computed: {
    reverseEvent() {
      return this.events.slice().reverse();
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile =
        window.innerWidth <= this.$store.state.mediaWidth ? true : false;
    },
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .event {
    position: relative;
    min-height: 100vh;
    width: calc(100% - 256px);
    background-color: #ffd000;
    padding: 40px 0;
    font-family: "Noto Sans JP";
  }
  /* hero image */
  .event__hero {
    text-align: center;
  }
  .event__hero__img {
    height: 320px;
  }

  /* content */
  .event__content {
    width: 600px;
    margin: 0 auto;
  }
  .event__content__area {
    padding: 0;
  }
  .event__content__area__item {
    font-size: 40px;
    font-weight: 400;
    margin: 40px 0 0 72px;
    transition: 0.2s;
  }
  .event__content__area__item:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
  .router-link {
    font-size: 22px;
    color: #444;
    transition: 0.2s;
    letter-spacing: 5px;
    font-weight: 600;
  }
  .router-link:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
  .icon {
    width: 24px;
  }
}
/* SP */
@media (max-width: 1070px) {
  .event {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-color: #ffd000;
    font-family: "Noto Sans JP";
  }
  .event__hero {
    text-align: center;
    padding: 40px 0 0 0;
  }
  .event__hero__img {
    width: 160px;
  }

  /* content */
  .event__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .event__content__area {
    padding: 0;
  }
  .event__content__area__item {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin: 40px auto;
    transition: 0.1s;
  }
  .event__content__area__item:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
  .router-link {
    color: #333;
    transition: 0.2s;
  }
  .router-link:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
  .icon {
    width: 22px;
    transform: translateY(2px);
  }
}
</style>
