<template>
  <div v-if="isMobile" @click="toggleNav">
    <div :class="`background ${navStateClass}`"></div>
    <div :class="`menu-bg`"></div>
    <div :class="`menu ${navStateClass}`">
      <span class="menu__line"></span>
      <span class="menu__line"></span>
      <span class="menu__line"></span>
    </div>
    <nav :class="`nav ${navStateClass}`">
      <ul class="nav__list">
        <li class="nav__list__item">
          <router-link class="link" to="/">
            <img class="link__img" :src="home" alt="nav"
          /></router-link>
        </li>
        <li class="nav__list__item">
          <router-link class="link" to="/me">
            <img class="link__img" :src="me" alt="nav"
          /></router-link>
        </li>
        <li class="nav__list__item">
          <router-link class="link" to="/work">
            <img class="link__img" :src="work" alt="nav"
          /></router-link>
        </li>
        <li class="nav__list__item">
          <router-link class="link" to="/sns">
            <img class="link__img" :src="sns" alt="nav"
          /></router-link>
        </li>
        <li class="nav__list__item">
          <router-link class="link" to="/event">
            <img class="link__img" :src="event" alt="nav"
          /></router-link>
        </li>
        <li class="nav__list__item">
          <router-link class="link" to="/contact">
            <img class="link__img" :src="contact" alt="nav"
          /></router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      isOpen: false,
      home: require("@/assets/nav/home.png"),
      me: require("@/assets/nav/pc/me.png"),
      work: require("@/assets/nav/pc/work.png"),
      sns: require("@/assets/nav/pc/sns.png"),
      event: require("@/assets/nav/pc/event.png"),
      contact: require("@/assets/nav/pc/contact.png"),
    };
  },
  computed: {
    inHome() {
      return this.$route.name === "Home";
    },
    navStateClass() {
      return this.isOpen ? "open" : "close";
    },
  },
  methods: {
    toggleNav() {
      this.isOpen = !this.isOpen;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768 ? true : false;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
/* mobile only */
.menu {
  position: fixed;
  top: 14px;
  right: 12px;
  z-index: 300;
}
.menu-bg {
  position: fixed;
  top: 0;
  right: 0;
  width: 64px;
  height: 56px;
  background-color: #fc427b;
  border-radius: 0 0 0 50%;
  z-index: 300;
}
.menu__line {
  display: block;
  width: 32px;
  height: 3px;
  background: #eee;
  border-radius: 16px;
  margin-bottom: 6px;
  z-index: 1500;
  transition: 0.5s;
}
nav {
  position: fixed;
  top: 0;
  left: -280px;
  width: 280px;
  height: 100%;
  padding: 80px 20px 20px 20px;
  background: #fc427b;
  box-sizing: border-box;
  z-index: 1000;
  transition: 0.4s 0.3s;
}
.nav__list__item {
  font-size: 24px;
  font-weight: 600;
  font-family: futura;
  text-transform: uppercase;
  margin: 0 0 24px 0;
}

.link {
}
.link__img {
  height: 32px;
}

.background {
  transition: 0.4s;
}

/* OPEN */
nav.open {
  left: 0;
}
.menu.open {
  transform: translateX(5px);
}
.menu.open span:nth-child(1) {
  transform: rotate(45deg) translateY(13px);
}
.menu.open span:nth-child(2) {
  opacity: 0;
  width: 0;
}
.menu.open span:nth-child(3) {
  transform: rotate(-45deg) translateY(-13px);
}
.background.open {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}
</style>
