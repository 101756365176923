<template>
  <h1>404</h1>
</template>
<script>
export default {
  created() {
    console.log("404");
  },
};
</script>

<style scoped>
* {
  background-color: #fff;
}
</style>
