<template>
  <div class="main">
    <ContentTitle
      :icon="icon"
      :title="title"
      style="background-color: #af7bdf"
    />
    <div class="content">
      <ul class="content__area">
        <template v-for="(item, index) in items" :key="index">
          <li class="content__area__item">
            <router-link
              class="content__area__item__link"
              :to="`/work/company/${item.to}`"
            >
              {{ item.title }}
            </router-link>
            <hr v-if="index !== items.length - 1" class="divider" />
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import ContentTitle from "@/components/ContentTitle";

export default {
  components: {
    ContentTitle,
  },

  data() {
    return {
      isMobile: false,
      icon: require("@/assets/work/password.png"),
      illustration: require("@/assets/work/illustration.png"),
      license: require("@/assets/work/license.png"),
      items: [
        {
          title: "Character products",
          to: "character",
          images: [
            // require("@/assets/work/company/character-product/disney/disney-1-min.png"),
          ],
        },
        {
          title: "Non Character products",
          to: "non-character",
          images: [
            // require("@/assets/work/company/non-character-product/non-1-min.png"),
          ],
        },
      ],
      title: "company work",
    };
  },
  computed: {
    reverseImages() {
      return this.images.slice().reverse();
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    try {
      const loginStatus = localStorage.getItem("loginStatus");
      if (loginStatus !== "true") this.$router.push("/work");
    } catch {
      this.$router.push("/work");
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile =
        window.innerWidth <= this.$store.state.mediaWidth ? true : false;
    },
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .main {
    width: calc(100% - 256px);
    min-height: 100vh;
    background-color: #fff;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    /* 56px is title height */
    height: calc(100vh - 56px);
    background-color: #af7bdf;
  }

  .content__area {
    font-family: futura;
    transform: translateY(-64px);
    text-align: center;
    padding: 0;
  }
  .content__area__item {
    margin: 40px 0;
  }

  .content__area__item__link {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 48px;
    letter-spacing: -1px;
  }

  .divider {
    width: 28vw;
    height: 0.2px;
    background-color: #fff;
    margin-top: 32px;
  }
}
/* SP */
@media (max-width: 1070px) {
  .main {
    width: 100vw;
    height: 100vh;
    background-color: #af7bdf;
  }
  .content {
    display: flex;
    justify-content: center;
    margin-top: 160px;
  }

  .content__area {
    font-family: futura;
    text-align: center;
    padding: 0;
    height: 240px;
  }
  .content__area__item {
    margin: 24px 0;
  }

  .content__area__item__link {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 28px;
    letter-spacing: -1px;
  }

  .divider {
    width: 40vw;
    height: 0.2px;
    background-color: #fff;
    margin-top: 24px;
  }
  * {
    /* outline: solid 1px green; */
  }
}
</style>
