<template>
  <div class="content">
    <ContentTitle :icon="icon" :title="title" />
    <div class="content__title-area">
      <div class="content__title-area__text">
        Product design, design planning, specification writing, <br />
        communication with factories, etc.
      </div>
    </div>
    <div class="main">
      <div
        v-for="img in reverseImages"
        class="card"
        :key="img"
        :style="this.isMobile ? `width: 90%` : `width: 45%`"
      >
        <img class="tile" :src="img" alt="item" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentTitle from "@/components/ContentTitle";

export default {
  components: {
    ContentTitle,
  },

  data() {
    return {
      isMobile: false,
      icon: require("@/assets/work/password.png"),
      illustration: require("@/assets/work/illustration.png"),
      license: require("@/assets/work/license.png"),
      images: [
        require("@/assets/work/company/non-character-product/non-1-min.png"),
        require("@/assets/work/company/non-character-product/non-2-min.png"),
        require("@/assets/work/company/non-character-product/non-3-min.png"),
        require("@/assets/work/company/non-character-product/non-4-min.png"),
        require("@/assets/work/company/non-character-product/non-5-min.png"),
        require("@/assets/work/company/non-character-product/non-6-min.png"),
        require("@/assets/work/company/non-character-product/non-7-min.png"),
        require("@/assets/work/company/non-character-product/non-8-min.png"),
        require("@/assets/work/company/non-character-product/non-9-min.png"),
        require("@/assets/work/company/non-character-product/non-10-min.png"),
        require("@/assets/work/company/non-character-product/non-11-min.png"),
        require("@/assets/work/company/non-character-product/non-12-min.png"),
        require("@/assets/work/company/non-character-product/non-13-min.png"),
        require("@/assets/work/company/non-character-product/non-14-min.png"),
        require("@/assets/work/company/non-character-product/non-15-min.png"),
        require("@/assets/work/company/non-character-product/non-16-min.png"),
        require("@/assets/work/company/non-character-product/non-17-min.png"),
        require("@/assets/work/company/non-character-product/non-18-min.png"),
        require("@/assets/work/company/non-character-product/non-19-min.png"),
        require("@/assets/work/company/non-character-product/non-20-min.png"),
        require("@/assets/work/company/non-character-product/non-21-min.png"),
        require("@/assets/work/company/non-character-product/non-22-min.png"),
        require("@/assets/work/company/non-character-product/non-23-min.png"),
      ],
      title: "company work",
    };
  },
  computed: {
    reverseImages() {
      return this.images.slice().reverse();
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile =
        window.innerWidth <= this.$store.state.mediaWidth ? true : false;
    },
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .content {
    width: calc(100% - 256px);
    min-height: 100vh;
    background-color: #fff;
  }

  .content__title-area {
    width: 100%;
    margin: 16px 32px;
  }
  .content__title-area__text {
    font-size: 24px;
    font-family: futura;
    font-weight: 600;
    color: #333;
  }
  .main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
  }
  .card {
    margin: 16px;
  }

  .tile {
    width: 100%;
  }
}
/* SP */
@media (max-width: 1070px) {
  .content {
    width: 100vw;
    min-height: 100vh;
    background-color: #fff;
  }

  .content__title-area {
    margin: 8px 16px;
  }
  .content__title-area__text {
    font-size: 16px;
    font-family: futura;
    font-weight: 600;
    color: #333;
  }
  .main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
  }
  .card {
    width: 45%;
    margin: 4px;
  }

  .tile {
    width: 100%;
  }
}
</style>
