<template>
  <div class="me">
    <div class="me__intro">
      <div class="me__intro__profile">
        <img class="me__intro__profile__img" :src="ito" alt="profile" />
      </div>
      <div class="me__intro__text">
        <span class="me__intro__text__name"> CANA ITO</span>
        <span class="me__intro__text__subname"> （いとかな/110）</span>
        <p class="divider"></p>
        <span>
          さまざまな業界の
          <br />
          デザインとイラストを手掛ける
          <br />
          マルチデザイナー
        </span>
        <p class="divider"></p>
      </div>
    </div>
    <div class="me__skill">
      <template v-for="item in items" :key="item">
        <ul class="me__skill__list">
          <li class="me__skill__list__item">
            <p class="me__skill__list__item__title">
              <img
                class="me__skill__list__item__title__check"
                :src="check"
                alt="check icon"
              />
              {{ item.title }}
            </p>
            <div class="me__skill__list__item__area">
              <p
                class="me__skill__list__item__area__item"
                v-for="text in item.texts"
                :key="text"
              >
                &#x25CF; {{ text }}
              </p>
              <p
                class="me__skill__list__item__area__item me__skill__list__item__area__item--etc"
              >
                etc
              </p>
            </div>
          </li>
        </ul>
      </template>
    </div>
    <div class="contact">
      <p class="contact__text">
        上記記載以外にもお気軽にお問合せください。<br />ご相談は
        <router-link class="contact__text__link" to="/contact"
          >コンタクトページ</router-link
        >
        よりお待ちしております。
      </p>
      <router-link to="/contact">
        <img class="contact__img" :src="mail" alt="メール画像" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Me",
  data() {
    return {
      isMobile: false,
      check: require("@/assets/me/check.png"),
      mail: require("@/assets/me/contact-link.png"),
      ito: require("@/assets/me/110.png"),
      items: [
        {
          title: "Item Design",
          texts: [
            "商品企画・開発デザイン",
            "パッケージデザイン",
            "CD ジャケット作成",
            "キャラクターグッズ企画デザイン",
            "イベント向け商品企画・開発",
          ],
        },
        {
          title: "Graphic Design",
          texts: [
            "アイキャッチ・アイコンデザイン",
            "名刺・広告デザイン",
            "ロゴデザイン",
            "レギュレーション作成",
            "他サイト素材作成",
          ],
        },
        {
          title: "Direction",
          texts: [
            "展示会ブースデザイン",
            "デザインコンサル",
            "デザインノウハウ",
            "アートディレクション",
            "ライブイベント",
          ],
        },
        {
          title: "Other",
          texts: [
            "イラストレーション作成",
            "イラスト 110ライセンス貸出",
            "個展",
          ],
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile =
        window.innerWidth <= this.$store.state.mediaWidth ? true : false;
    },
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .me {
    position: relative;
    width: calc(100% - 256px);
    background-color: #fe7baa;
    padding: 0 0 40px 0;
    font-family: "Noto Sans JP";
  }
  .me__intro {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 520px;
    margin: 0 auto;
    padding: 80px 0 0 0;
  }
  .me__intro__profile {
    text-align: center;
  }
  .me__intro__profile__img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  .me__intro__text {
    font-weight: 200;
    text-align: center;
    color: #fff;
  }
  .me__intro__text__name {
    font-family: futura;
    font-size: 24px;
    font-weight: 600;
  }
  .me__intro__text__subname {
    display: block;
    font-family: futura;
    font-size: 16px;
  }

  /* skill */
  .me__skill {
    width: 72%;
    margin: 0 auto;
  }
  .me__skill__list {
    margin: 0 0 32px 0;
    padding: 0;
  }
  .me__skill__list__item {
  }
  .me__skill__list__item__title {
    background-color: #fc427b;
    color: #fff;
    padding: 8px 24px;
    border-radius: 16px;
    font-size: 20px;
    font-family: futura;
    font-weight: 400;
  }
  .me__skill__list__item__area {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 56px;
  }
  .me__skill__list__item__area__item {
    color: #fff;
    width: 33%;
    font-size: 12px;
    margin: 4px 0;
  }
  .me__skill__list__item__title__check {
    width: 18px;
    margin: 0 8px 0 0;
    transform: translateY(0px);
  }
  .me__skill__list__item__area__item--etc {
    width: 100%;
    text-align: end;
    font-weight: 400;
    font-family: futura;
  }

  /* contact */
  .contact {
    text-align: center;
    margin: 40px 0 0 0;
  }
  .contact__text {
    color: #fff;
  }
  .contact__text__link {
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
  }
  .contact__img {
    margin: 24px;
    width: 64px;
    transition: 0.2s;
  }
  .contact__img:hover {
    transform: scale(1.05);
  }

  .divider {
    width: 100%;
    border-bottom: solid 1px #fff;
  }
}

/* SP */
@media (max-width: 1070px) {
  .me {
    position: relative;
    background-color: #fe7baa;
    padding: 0 0 80px 0;
    font-family: "Noto Sans JP";
  }
  .me__intro {
    position: relative;
  }
  .me__intro__profile {
    text-align: center;
  }
  .me__intro__profile__img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin: 24px 0;
  }
  .me__intro__text {
    font-weight: 600;
    text-align: center;
    width: 240px;
    color: #fff;
    font-size: 12px;
    margin: 0 auto 40px auto;
  }
  .me__intro__text__name {
    font-size: 22px;
  }

  /* skill */
  .me__skill {
    width: 90%;
    margin: 0 auto;
  }
  .me__skill__list {
    margin: 0 0 8px 0;
    padding: 0;
  }
  .me__skill__list__item {
  }
  .me__skill__list__item__title {
    background-color: #fc427b;
    color: #fff;
    padding: 12px 24px;
    border-radius: 16px;
    font-family: futura;
    font-weight: 400;
  }
  .me__skill__list__item__area {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 40px;
  }
  .me__skill__list__item__area__item {
    color: #fff;
    width: 100%;
    font-size: 12px;
    margin: 4px 0;
  }
  .me__skill__list__item__title__check {
    width: 16px;
    margin: 0 8px 0 0;
    transform: translateY(2px);
  }
  .me__skill__list__item__area__item--etc {
    text-align: end;
    font-weight: 400;
    font-family: futura;
    margin: 0 40px 0 0;
  }

  /* contact */
  .contact {
    text-align: center;
    margin: 40px 0 0 0;
  }
  .contact__text {
    color: #fff;
    font-size: 12px;
  }
  .contact__text__link {
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
  }
  .contact__img {
    margin: 24px;
    width: 64px;
  }

  .divider {
    width: 100%;
    border-bottom: solid 1px #fff;
  }
}
</style>
