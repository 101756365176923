<template>
  <div class="home">
    <div class="movie">
      <div class="movie__title">
        <img class="movie__title__img" :src="homeTitle" alt="" />
      </div>
      <video
        class="movie__item"
        autoplay
        loop
        muted
        playsinline
        :poster="canaStaticImg"
        webkit-playsinline
      >
        <source :src="canaMovie" type="video/mp4" />
      </video>
    </div>
    <div class="nav-area">
      <ul class="nav">
        <li class="nav__item">
          <router-link class="link" to="/me">
            <div class="link__text">me</div>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link class="link" to="/work">
            <div class="link__text">work</div>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link class="link" to="/sns">
            <div class="link__text">sns</div>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link class="link" to="/event">
            <div class="link__text">event</div>
          </router-link>
        </li>
        <li class="nav__item">
          <router-link class="link" to="/contact">
            <div class="link__text">contact</div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isMobile: false,
      hero: require("@/assets/home/cana.png"),
      canaMovie: require("@/assets/home/cana.mp4"),
      homeTitle: require("@/assets/home/home-title.png"),

      // PC
      mePC: require("@/assets/home/pc/me.png"),
      workPC: require("@/assets/home/pc/work.png"),
      snsPC: require("@/assets/home/pc/sns.png"),
      eventPC: require("@/assets/home/pc/event.png"),
      contactPC: require("@/assets/home/pc/contact.png"),
      // SP
      meSP: require("@/assets/nav/pc/me.png"),
      workSP: require("@/assets/nav/pc/work.png"),
      snsSP: require("@/assets/nav/pc/sns.png"),
      eventSP: require("@/assets/nav/pc/event.png"),
      contactSP: require("@/assets/nav/pc/contact.png"),
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile =
        window.innerWidth <= this.$store.state.mediaWidth ? true : false;
    },
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .home {
    background-color: #fff;
    z-index: 200;
    height: 100vh;
    position: relative;
  }
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: #ff5c6e;
    z-index: 200;
    height: 100vh;
  }
  .movie {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 110vw;
    overflow: hidden;
    z-index: -1;
  }
  .movie__title {
    display: none;
  }
  .movie__item {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    z-index: -1;
  }
  .nav-area {
    display: flex;
    justify-content: center;
    align-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 180px;
    opacity: 0.8;
    background-color: #fc427b;
  }
  .nav {
    display: flex;
    padding: 0;
  }
  .nav__item {
    margin: auto 24px;
  }
  .link {
    text-decoration: none;
    display: inline-block;
    position: relative;
  }
  .link::after {
    position: absolute;
    bottom: -4px;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    background: #fff;
    transform: scale(0, 1);
    transform-origin: left top;
    transition: transform 0.3s;
  }
  .link:hover::after {
    transform: scale(1, 1);
  }
  .link__text {
    font-size: 40px;
    color: #fff;
    font-family: futura;
    font-weight: 600;
    margin: auto 24px;
    text-transform: uppercase;
  }
  .link:hover {
    opacity: 0.9;
  }
  .link__img {
    height: 120px;
  }
}

/* SP */
@media (max-width: 1070px) {
  .home {
    position: relative;
    height: 100vh;
    z-index: 200;
  }
  .movie {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 120vw;
    overflow: hidden;
    z-index: -1;
  }
  .movie__title {
    position: absolute;
    top: 20vh;
    left: 50vw;
    transform: translate(-45%, -50%);
  }
  .movie__title__img {
    width: 240px;
  }
  .movie__item {
    position: absolute;
    top: 50vh;
    left: 52vw;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    z-index: -1;
  }
  .nav-area {
    display: none;
  }
  .link__img {
    height: 24%;
    margin: 20px 0;
  }
}
</style>
