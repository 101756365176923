<template>
  <nav v-if="!inHome" class="nav">
    <ul class="nav__list">
      <li class="nav__list__item">
        <router-link class="link" to="/">
          <img class="link__img" :src="homePC" alt="nav" />
        </router-link>
      </li>
      <li class="nav__list__item">
        <router-link class="link" to="/me">
          <img class="link__img" :src="mePC" alt="nav" />
        </router-link>
      </li>
      <li class="nav__list__item">
        <router-link class="link" to="/work">
          <img class="link__img" :src="workPC" alt="nav" />
        </router-link>
      </li>
      <li class="nav__list__item">
        <router-link class="link" to="/sns">
          <img class="link__img" :src="snsPC" alt="nav" />
        </router-link>
      </li>
      <li class="nav__list__item">
        <router-link class="link" to="/event">
          <img class="link__img" :src="eventPC" alt="nav" />
        </router-link>
      </li>
      <li class="nav__list__item">
        <router-link class="link" to="/contact">
          <img class="link__img" :src="contactPC" alt="nav" />
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      homePC: require("@/assets/nav/home.png"),
      mePC: require("@/assets/nav/pc/me.png"),
      workPC: require("@/assets/nav/pc/work.png"),
      snsPC: require("@/assets/nav/pc/sns.png"),
      eventPC: require("@/assets/nav/pc/event.png"),
      contactPC: require("@/assets/nav/pc/contact.png"),
    };
  },
  computed: {
    inHome() {
      return this.$route.name === "Home";
    },
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .nav {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    width: 256px;
    height: 100vh;
    background-color: #fc427b;
    z-index: 100;
  }
  .nav__list {
    height: 688px;
    margin: auto 0 auto 40px;
    padding: 0;
  }
  .nav__list__item {
    margin: 48px 0;
  }
  .link {
    color: #fff;
    text-decoration: none;
    transition: 0.2s;
  }
  .link__img {
    height: 40px;
  }
  .link:hover {
    opacity: 0.7;
  }
}
/* SP */
@media (max-width: 1070px) {
  .nav {
    display: none;
  }
}
</style>
