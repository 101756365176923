<template>
  <div class="container">
    <Loading v-show="loading" />
    <div>
      <MobileMenu class="mobile-menu" v-if="this.isMobile" />
      <SideNav v-else />
      <div class="contents">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import MobileMenu from "@/components/MobileMenu.vue";
import SideNav from "@/components/SideNav.vue";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    MobileMenu,
    SideNav,
    Loading,
  },
  data() {
    return {
      loading: true,
      isMobile: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile =
        window.innerWidth <= this.$store.state.mediaWidth ? true : false;
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  font-family: "游ゴシック", "Times", sans-serif;
}
li {
  list-style: none;
  padding: 0;
}
a {
  text-decoration: none;
}
</style>
