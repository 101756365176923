import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Me from "@/views/Me.vue";
import Work from "@/views/work/index.vue";
import Goods from "@/views/work/Goods.vue";
import Web from "@/views/work/Web.vue";
import Company from "@/views/work/company/index.vue";
import Character from "@/views/work/company/Character.vue";
import nonCharacter from "@/views/work/company/NonCharacter.vue";
import illust from "@/views/work/illust.vue";
import License from "@/views/work/License.vue";
import SNS from "@/views/Sns.vue";
import Event from "@/views/event/index.vue";
import Gift from "@/components/Gift.vue";
import Contact from "@/views/contact/index.vue";

import SiteContact from "@/views/contact/site.vue";
import NotFound from "@/views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/me",
    name: "Me",
    component: Me,
  },

  {
    path: "/work",
    name: "Work",
    component: Work,
  },
  {
    path: "/work/goods",
    name: "Goods",
    component: Goods,
  },
  {
    path: "/work/web",
    name: "Web",
    component: Web,
  },
  {
    path: "/work/company",
    name: "Company",
    component: Company,
  },
  {
    path: "/work/company/character",
    name: "Character",
    component: Character,
  },
  {
    path: "/work/company/non-character",
    name: "NonCharacter",
    component: nonCharacter,
  },
  {
    path: "/work/illustration",
    name: "illustration",
    component: illust,
  },
  {
    path: "/work/license",
    name: "License",
    component: License,
  },

  {
    path: "/event",
    name: "Event",
    component: Event,
  },
  {
    path: "/event/:id",
    name: "Gift",
    component: Gift,
  },

  {
    path: "/sns",
    name: "sns",
    component: SNS,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/contact/website",
    name: "SiteContact",
    component: SiteContact,
  },
  {
    path: "/*",
    name: "404",
    conponent: NotFound,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
