<template>
  <Content :title="pageTitle" :icon="icon" :col="col" :images="images" />
</template>

<script>
import Content from "@/components/Content.vue";
export default {
  components: {
    Content,
  },
  data() {
    return {
      images: [
        require(`@/assets/work/illustration/illustration_1-min.png`),
        require(`@/assets/work/illustration/illustration_2-min.png`),
        require(`@/assets/work/illustration/illustration_3-min.png`),
        require(`@/assets/work/illustration/illustration_4-min.png`),
        require(`@/assets/work/illustration/illustration_5-min.png`),
        require(`@/assets/work/illustration/illustration_6-min.png`),
        require(`@/assets/work/illustration/illustration_7-min.png`),
        // require(`@/assets/work/illustration/illustration_8-min.png`),
        require(`@/assets/work/illustration/illustration_9-min.png`),
        require(`@/assets/work/illustration/illustration_1-min.png`),
        require(`@/assets/work/illustration/illustration_10-min.png`),
        require(`@/assets/work/illustration/illustration_11-min.png`),
        require(`@/assets/work/illustration/illustration_12-min.png`),
        require(`@/assets/work/illustration/illustration_13-min.png`),
        require(`@/assets/work/illustration/illustration_14-min.png`),
        require(`@/assets/work/illustration/illustration_15-min.png`),
        require(`@/assets/work/illustration/illustration_16-min.png`),
        require(`@/assets/work/illustration/illustration_17-min.png`),
        require(`@/assets/work/illustration/illustration_18-min.png`),
        require(`@/assets/work/illustration/illustration_19-min.png`),
        require(`@/assets/work/illustration/illustration_20-min.png`),
        require(`@/assets/work/illustration/illustration_21-min.png`),
        require(`@/assets/work/illustration/illustration_22-min.png`),
        require(`@/assets/work/illustration/illustration_23-min.png`),
        require(`@/assets/work/illustration/illustration_24-min.png`),
        require(`@/assets/work/illustration/illustration_25-min.png`),
        require(`@/assets/work/illustration/illustration_26-min.png`),
        require(`@/assets/work/illustration/illustration_27-min.png`),
        require(`@/assets/work/illustration/illustration_28-min.png`),
        require(`@/assets/work/illustration/illustration_29-min.png`),
        require(`@/assets/work/illustration/illustration_30-min.png`),
        require(`@/assets/work/illustration/illustration_31-min.png`),
        require(`@/assets/work/illustration/illustration_32-min.png`),
        require(`@/assets/work/illustration/illustration_33-min.png`),
        require(`@/assets/work/illustration/illustration_34-min.png`),
        require(`@/assets/work/illustration/illustration_35-min.png`),
        require(`@/assets/work/illustration/illustration_36-min.png`),
        require(`@/assets/work/illustration/illustration_37-min.png`),
        require(`@/assets/work/illustration/illustration_38-min.png`),
        require(`@/assets/work/illustration/illustration_39-min.png`),
        require(`@/assets/work/illustration/illustration_40-min.png`),
        require(`@/assets/work/illustration/illustration_41-min.png`),
        require(`@/assets/work/illustration/illustration_42-min.png`),
        require(`@/assets/work/illustration/illustration_43-min.png`),
        require(`@/assets/work/illustration/illustration_44-min.png`),
        require(`@/assets/work/illustration/illustration_45-min.png`),
        require(`@/assets/work/illustration/illustration_46-min.png`),
        require(`@/assets/work/illustration/illustration_47-min.png`),
        require(`@/assets/work/illustration/illustration_48-min.png`),
        require(`@/assets/work/illustration/illustration_49-min.png`),
        require(`@/assets/work/illustration/illustration_50-min.png`),
        require(`@/assets/work/illustration/illustration_51-min.png`),
        require(`@/assets/work/illustration/illustration_52-min.png`),
        require(`@/assets/work/illustration/illustration_53-min.png`),
        require(`@/assets/work/illustration/illustration_54-min.png`),
        require(`@/assets/work/illustration/illustration_55-min.png`),
        require(`@/assets/work/illustration/illustration_56-min.png`),
        require(`@/assets/work/illustration/illustration_57-min.png`),
        require(`@/assets/work/illustration/illustration_58-min.png`),
        require(`@/assets/work/illustration/illustration_59-min.png`),
        require(`@/assets/work/illustration/illustration_60-min.png`),
        require(`@/assets/work/illustration/illustration_61-min.png`),
        require(`@/assets/work/illustration/illustration_62-min.png`),
        require(`@/assets/work/illustration/illustration_63-min.png`),
        require(`@/assets/work/illustration/illustration_64-min.png`),
        require(`@/assets/work/illustration/illustration_65-min.png`),
        require(`@/assets/work/illustration/illustration_66-min.png`),
        require(`@/assets/work/illustration/illustration_67-min.png`),
        require(`@/assets/work/illustration/illustration_68-min.png`),
        require(`@/assets/work/illustration/illustration_69-min.png`),
        require(`@/assets/work/illustration/illustration_70-min.png`),
        require(`@/assets/work/illustration/illustration_71-min.png`),
        require(`@/assets/work/illustration/illustration_72-min.png`),
        require(`@/assets/work/illustration/illustration_73-min.png`),
      ],
      icon: require("@/assets/work/illustration.png"),
      col: 5,
      pageTitle: "Illustration",
    };
  },
};
</script>
