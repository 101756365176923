<template>
  <div class="content">
    <ContentTitle :icon="icon" :title="title" />

    <div class="main">
      <div class="hero">
        <img class="hero__img" :src="hero" alt="hero image" />
      </div>

      <div class="contact">
        <p class="contact__text">
          ライセンス使用に関するご依頼は
          <router-link class="contact__text__link" to="/contact"
            >コンタクトページ</router-link
          >
          よりお問合せください。
        </p>
      </div>

      <div class="divider"></div>

      <div class="art" v-for="(content, index) in contents" :key="content.id">
        <div class="art__title">art title</div>
        <div
          :class="
            index % 2 === 0
              ? `art__contents`
              : `art__contents art__contents--odd`
          "
        >
          <img
            v-for="img in content.images"
            :key="img.id"
            class="art__contents__img"
            :src="img"
            alt="art image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentTitle from "@/components/ContentTitle";

export default {
  components: { ContentTitle },
  data() {
    return {
      icon: require("@/assets/work/license.png"),
      title: "License Assets",
      hero: require(`@/assets/work/license/license-top.png`),
      contents: [
        {
          images: [
            require(`@/assets/work/license/license-1-1.png`),
            require(`@/assets/work/license/license-1-2.png`),
          ],
        },
        {
          images: [
            require(`@/assets/work/license/license-2-1.png`),
            require(`@/assets/work/license/license-2-2.png`),
          ],
        },
        {
          images: [
            require(`@/assets/work/license/license-3-1.png`),
            require(`@/assets/work/license/license-3-2.png`),
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
@media (min-width: 1071px) {
  .content {
    width: calc(100% - 256px);
    min-height: 100vh;
    background-color: #fff;
  }
  .main {
    width: 90%;
    margin: 24px auto 0 auto;
  }
  .hero {
    width: 100%;
  }
  .hero__img {
    width: 100%;
  }

  .contact {
    text-align: center;
  }
  .contact__text {
    font-weight: bold;
    font-size: 16px;
    font-family: futura;
  }
  .contact__text__link {
    color: #333;
    text-decoration: underline;
  }
  .contact__text__link:hover {
    opacity: 0.7;
  }

  .divider {
    width: 100%;
    border-bottom: dashed 4px #ccc;
  }

  .art {
    margin: 24px 0;
  }
  .art__title {
    font-family: futura;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 40px 0 16px 16px;
  }
  .art__contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .art__contents--odd {
    flex-direction: row-reverse;
  }
  .art__contents__img {
    width: 45%;
  }
}
@media (max-width: 1070px) {
  .content {
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    background-color: #fff;
  }
  .main {
    width: 100%;
  }
  .hero {
    margin: 12px 0 0 0;
  }
  .hero__img {
    width: 100%;
  }

  .contact {
    text-align: center;
  }
  .contact__text {
    font-weight: bold;
    font-size: 10px;
    font-family: futura;
  }
  .contact__text__link {
    color: #333;
    text-decoration: underline;
  }
  .contact__text__link:hover {
    opacity: 0.7;
  }

  .divider {
    width: 100%;
    border-bottom: dashed 4px #ccc;
  }

  .art {
    margin: 24px 0;
  }
  .art__title {
    font-family: futura;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 40px 0 16px 40px;
  }
  .art__contents {
    margin: 0 auto;
    text-align: center;
  }
  .art__contents__img {
    width: 85%;
  }
}
</style>
