<template>
  <div class="content">
    <ContentTitle :icon="icon" :title="title" />
    <div class="main">
      <div
        v-for="img in images"
        class="card"
        :key="img"
        :style="this.isMobile ? `` : `width: ${calcWidth}%`"
      >
        <img class="tile" :src="img" alt="item" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentTitle from "@/components/ContentTitle";

export default {
  props: {
    title: {
      type: String,
      default: "Title",
    },
    icon: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      default: 2,
    },
    images: {
      type: Object,
      required: true,
    },
  },

  components: {
    ContentTitle,
  },

  data() {
    return {
      isMobile: false,
      heart: require("@/assets/work/heart.png"),
      illustration: require("@/assets/work/illustration.png"),
      license: require("@/assets/work/license.png"),
    };
  },
  computed: {
    reverseImages() {
      return this.images.slice().reverse();
    },
    calcWidth() {
      return `${(100 - 14) / this.col}`;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile =
        window.innerWidth <= this.$store.state.mediaWidth ? true : false;
    },
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .content {
    width: calc(100% - 256px);
    min-height: 100vh;
    background-color: #fff;
  }
  .main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
  }
  .card {
    margin: 16px;
  }

  .tile {
    width: 100%;
  }
}
/* SP */
@media (max-width: 1070px) {
  .content {
    width: 100vw;
    min-height: 100vh;
    background-color: #fff;
  }
  .main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
  }
  .card {
    width: 45%;
    margin: 4px;
  }

  .tile {
    width: 100%;
  }
}
</style>
