<template>
  <div class="work">
    <div class="work__hero">
      <img class="work__hero__img" :src="hero" alt="hero img" />
    </div>

    <div class="work__area">
      <ul class="work__area__list">
        <li
          v-for="(item, index) in workItems"
          class="work__area__list__item"
          :key="item.id"
        >
          <router-link
            v-if="item.id !== 'company'"
            class="router-link"
            :to="item.to"
          >
            <img class="item__icon" :src="getItemIcon[index]" alt="check" />
            <div class="item__title">
              {{ item.text }}
            </div>
          </router-link>
          <router-link
            v-else
            class="router-link"
            @click="openLockedPage"
            :to="item.to"
          >
            <img class="item__icon" :src="getItemIcon[index]" alt="check" />
            <div class="item__title">
              {{ item.text }}
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,

      hero: require("@/assets/work/hero.png"),
      heart: require("@/assets/work/heart.png"),
      web: require("@/assets/work/web.png"),
      password: require("@/assets/work/password.png"),
      illustration: require("@/assets/work/illustration.png"),
      license: require("@/assets/work/license.png"),

      workItems: [
        { id: "goods", to: "/work/goods", text: "graphic・goods" },
        { id: "web", to: "/work/web", text: "graphic・web" },
        { id: "company", to: "/work/company", text: "company work" },
        { id: "illustration", to: "/work/illustration", text: "illustration" },
        { id: "license", to: "/work/license", text: "license assets" },
      ],
    };
  },
  computed: {
    getItemIcon() {
      return [
        this.heart,
        this.web,
        this.password,
        this.illustration,
        this.license,
      ];
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile =
        window.innerWidth <= this.$store.state.mediaWidth ? true : false;
    },
    openLockedPage() {
      const userInput = prompt(`【password】\nPlease ask me.`);
      if (userInput === "110") {
        localStorage.setItem("loginStatus", "true");
      } else {
        this.$router.push("/work");
        alert("パスワードが異なります");
      }
    },
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .work {
    position: relative;
    background-color: #af7bdf;
    width: calc(100% - 256px);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .work__hero {
  }
  .work__hero__img {
    height: 440px;
  }
  .work__area {
  }
  .work__area__list {
    padding: 0;
    margin: 40px 0 0 32px;
  }
  .work__area__list__item {
    margin: 32px 0;
    transition: 0.2s;
  }
  .work__area__list__item:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
  .router-link {
    display: flex;
    align-items: center;
  }
  .item__icon {
    width: 48px;
    height: 48px;
    padding: 0 24px 0 0;
  }
  .item__title {
    color: #fff;
    font-size: 48px;
    font-family: futura;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -1px;
    z-index: 20;
  }
}
/* SP */
@media (max-width: 1070px) {
  .work {
    position: relative;
    background-color: #af7bdf;
    height: 120vh;
  }
  .work__hero {
    padding: 80px 0 0 0;
    text-align: center;
    transform: translateX(-2px);
  }
  .work__hero__img {
    height: 320px;
  }
  .work__area {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .work__area__list {
    padding: 0;
  }
  .work__area__list__item {
    margin: 32px 0;
    transition: 0.2s;
  }
  .work__area__list__item:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
  .router-link {
    display: flex;
    align-items: center;
  }
  .item__icon {
    width: 32px;
    height: 32px;
    padding: 0 16px 0 0;
  }
  .item__title {
    color: #fff;
    font-size: 32px;
    font-family: futura;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -1px;
    z-index: 20;
  }
}
</style>
