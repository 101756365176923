<template>
  <div class="content">
    <ContentTitle :icon="icon" :title="title" />
    <div class="main">
      <div
        v-for="img in reverseImages"
        class="card"
        :key="img"
        :style="this.isMobile ? `width: 90%` : `width: 45%`"
      >
        <img class="tile" :src="img" alt="item" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentTitle from "@/components/ContentTitle";

export default {
  components: {
    ContentTitle,
  },

  data() {
    return {
      isMobile: false,
      icon: require("@/assets/work/web.png"),
      illustration: require("@/assets/work/illustration.png"),
      license: require("@/assets/work/license.png"),
      images: [
        require(`@/assets/work/web/1.png`),
        require(`@/assets/work/web/2.png`),
        require(`@/assets/work/web/3.png`),
        require(`@/assets/work/web/4.png`),
        require(`@/assets/work/web/5.png`),
        require(`@/assets/work/web/6.png`),
        require(`@/assets/work/web/7.png`),
        require(`@/assets/work/web/8.png`),
        require(`@/assets/work/web/9.png`),
        require(`@/assets/work/web/10.png`),
        require(`@/assets/work/web/11.png`),
        require(`@/assets/work/web/12.png`),
        require(`@/assets/work/web/13.png`),
        require(`@/assets/work/web/14.png`),
        require(`@/assets/work/web/15.png`),
        require(`@/assets/work/web/16.png`),
        require(`@/assets/work/web/17.png`),
        require(`@/assets/work/web/18.png`),
        require(`@/assets/work/web/19.png`),
        require(`@/assets/work/web/20.png`),
        require(`@/assets/work/web/21.png`),
      ],
      title: "graphic・web",
    };
  },
  computed: {
    reverseImages() {
      return this.images.slice().reverse();
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile =
        window.innerWidth <= this.$store.state.mediaWidth ? true : false;
    },
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .content {
    width: calc(100% - 256px);
    min-height: 100vh;
    background-color: #fff;
  }
  .main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
  }
  .card {
    margin: 16px;
  }

  .tile {
    width: 100%;
  }
}
/* SP */
@media (max-width: 1070px) {
  .content {
    width: 100vw;
    min-height: 100vh;
    background-color: #fff;
  }
  .main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
  }
  .card {
    width: 45%;
    margin: 4px;
  }

  .tile {
    width: 100%;
  }
}
</style>
