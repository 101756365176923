<template>
  <div class="contact">
    <div class="title">
      cont<span class="title__space title__space--a">a</span
      ><span class="title__space">c</span>t
    </div>
    <img class="hagaki" :src="postalHandPath" alt="hand" />
    <img v-if="!inTyping" class="post" :src="boxPath" alt="mail box" />
    <form
      class="form"
      action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSfNsecEQ62yGN152qgLl3IO-51EAl9gwnnYoEK3FObAsd4QtA/formResponse"
      name="contactForm"
      target="dummyIframe"
    >
      <div class="area">
        <div class="area__email">
          <label for="email" class="area__email__label">&#9660; email</label>
          <input
            class="area__email__input"
            type="email"
            name="entry.1492375782"
            placeholder="email@email.com"
            autocomplete="off"
            maxlength="100"
            v-model="state.email"
            required
          />
        </div>
        <div class="area__name">
          <label for="name" class="area__name__label">&#9660; name</label>
          <input
            class="area__name__input"
            type="text"
            name="entry.734443695"
            placeholder="Please enter your name."
            autocomplete="off"
            maxlength="20"
            v-model="state.name"
            required
          />
        </div>
      </div>
      <div class="message-area">
        <label for="message" class="message__label">message</label>
        <textarea
          class="message__input"
          name="entry.1653018165"
          autocomplete="off"
          rows="4"
          cols="10"
          maxlength="1000"
          v-model="state.message"
          required
        ></textarea>
      </div>
      <div class="submit">
        <button class="submit__btn" type="submit" @click="submitForm()">
          submit
        </button>
      </div>
    </form>
    <iframe name="dummyIframe" style="display: none"></iframe>
    <div id="snackbar">問い合わせ完了</div>
    <div id="snackbar-error">入力内容をご確認ください</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      postalHandPath: require("@/assets/contact/hagaki.png"),
      boxPath: require("@/assets/contact/post.png"),
      state: {
        valid: false,
        email: "",
        name: "",
        message: "",
      },
      inTyping: false,
    };
  },
  methods: {
    submitForm() {
      if (this.validate()) {
        document.contactForm.submit();
        this.showSnackBar("snackbar");
        this.clearForm();
      } else {
        this.showSnackBar("snackbar-error");
      }
    },
    validate() {
      return (this.state.valid =
        this.validEmail(this.state.email) &&
        this.validName(this.state.name) &&
        this.validMessage(this.state.message)
          ? true
          : false);
    },
    showSnackBar(id) {
      const snack = document.getElementById(`${id}`);
      snack.className = "show";
      setTimeout(() => {
        snack.className = snack.className.replace("show", "");
      }, 3000);
    },
    validEmail(payload) {
      if (!payload) return false;
      const pattern = new RegExp(
        /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
      );
      return pattern.test(payload);
    },
    validName(payload) {
      if (!payload) return false;
      return payload.length <= 20;
    },
    validMessage(payload) {
      if (!payload) return false;
      return payload.length <= 1000;
    },
    clearForm() {
      this.state.email = "";
      this.state.name = "";
      this.state.message = "";
    },
  },
};
</script>

<style scoped>
/* SnackBar */
#snackbar {
  visibility: hidden;
  text-align: center;
  background-color: #06c655;
  color: #fff;
  text-align: center;
  border: solid 1px #fff;
  border-radius: 8px;
  width: 280px;
  padding: 8px 24px;
  position: fixed;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  z-index: 1;
}

#snackbar.show {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbar-error {
  visibility: hidden;
  text-align: center;
  background-color: yellow;
  color: #333;
  text-align: center;
  border: solid 1px #fff;
  border-radius: 8px;
  width: 280px;
  padding: 8px 24px;
  position: fixed;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  z-index: 1;
}

#snackbar-error.show {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 40px;
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    bottom: 40px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

/* PC */
@media (min-width: 1071px) {
  .contact {
    position: relative;
    height: 100vh;
    width: calc(100% - 256px);
    background-color: #06dafa;
  }

  .title {
    position: absolute;
    top: 4%;
    left: 4%;
    font-size: 80px;
    font-family: futura;
    font-weight: 900;
    letter-spacing: 0;
    text-transform: uppercase;
    color: #1d4d83;
    z-index: 100;
  }
  .title__space {
    letter-spacing: 4px;
  }
  .title__space--a {
    letter-spacing: -2px;
  }

  .hagaki {
    position: absolute;
    top: 0;
    right: 120px;
    width: 240px;
  }

  .post {
    position: absolute;
    right: 120px;
    bottom: 4%;
    width: 288px;
  }

  .form {
    position: absolute;
    bottom: 28vh;
    left: 4vw;
    width: 640px;
  }
  /* email, name */
  .area {
    display: flex;
    justify-content: space-between;
    margin: 4px;
  }
  .area__email,
  .area__name {
    width: 100%;
  }

  .area__email__label,
  .area__name__label {
    color: #1d4d83;
    font-size: 24px;
    font-weight: 900;
    font-family: futura;
  }
  .area__email__input,
  .area__name__input {
    outline: none;
    border: none;
    width: 95%;
    height: 40px;
    padding: 0 0 0 8px;
    color: #1d4d83;
    font-size: 24px;
    background-color: transparent;
    border-top: solid 2px #1d4d83;
  }

  .area__email__input::placeholder,
  .area__name__input::placeholder {
    color: #1d4d83;
    opacity: 0.3;
  }

  /* message */
  .message-area {
    border: 2px #1d4d83 solid;
    border-radius: 8px;
    padding: 4px 8px;
    margin: 16px 0 0 0;
    height: 160px;
  }
  .message__input {
    border: none;
    outline: none;
    width: 95%;
    font-size: 18px;
    color: #1d4d83;
    padding: 8px 16px;
    background-color: transparent;
    resize: none;
  }
  .message__label {
    color: #1d4d83;
    background-color: transparent;
    font-size: 24px;
    font-weight: 900;
    font-family: futura;
  }

  .submit {
    margin: 16px 0 0 0;
    text-align: center;
  }
  .submit__btn {
    border-radius: 16px;
    border: solid 2px #1d4d83;
    color: #fff;
    background-color: #1d4d83;
    padding: 2px 16px;
    font-weight: 900;
    font-size: 24px;
    font-family: futura;
    text-transform: uppercase;
    transition: 0.2s;
  }
  .submit__btn:hover {
    opacity: 0.99;
    transform: scale(0.99);
    color: #1d4d83;
    background-color: #fff;
  }
}
/* SP */
@media (max-width: 1070px) {
  .contact {
    position: relative;
    background-color: #06dafa;
    height: 100vh;
    overflow: hidden;
  }

  .title {
    position: relative;
    top: 2vh;
    left: 4vw;
    font-size: 54px;
    font-family: futura;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -1px;
    color: #1d4d83;
    z-index: 100;
  }

  .hagaki {
    position: absolute;
    top: 0;
    right: -48px;
    width: 200px;
  }

  .post {
    position: absolute;
    bottom: 4vh;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    width: 240px;
  }

  .form {
    position: relative;
    top: 16vh;
    width: 90%;
    margin: 0 auto;
  }
  /* email, name */
  .area {
    margin: 4px;
  }
  .area__email,
  .area__name {
  }

  .area__email__label,
  .area__name__label {
    color: #1d4d83;
    font-size: 24px;
    font-weight: 900;
    font-family: futura;
  }
  .area__email__input,
  .area__name__input {
    outline: none;
    border: none;
    width: 95%;
    height: 40px;
    padding: 0 0 0 8px;
    color: #1d4d83;
    font-size: 24px;
    background-color: transparent;
    border-top: solid 2px #1d4d83;
  }

  .area__email__input::placeholder,
  .area__name__input::placeholder {
    color: #1d4d83;
    opacity: 0.3;
  }

  /* message */
  .message-area {
    border: 2px #1d4d83 solid;
    border-radius: 8px;
    padding: 4px 8px;
    margin: 16px 0 0 0;
  }
  .message__input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 18px;
    color: #1d4d83;
    background-color: transparent;
    resize: none;
  }
  .message__label {
    color: #1d4d83;
    background-color: transparent;
    font-size: 24px;
    font-weight: 900;
    font-family: futura;
  }

  .submit {
    margin: 16px 0 0 0;
    text-align: center;
  }
  .submit__btn {
    border-radius: 16px;
    border: solid 2px #1d4d83;
    color: #fff;
    background-color: #1d4d83;
    padding: 2px 12px;
    font-weight: 900;
    font-size: 22px;
    font-family: futura;
    text-transform: uppercase;
    transition: 0.2s;
  }
  .submit__btn:hover {
    opacity: 0.99;
    transform: scale(0.99);
    color: #1d4d83;
    background-color: #fff;
  }
}
</style>
