import { createApp } from "vue";
import { createStore } from "vuex";
import App from "./App.vue";
import router from "./router";

const store = createStore({
  state() {
    return {
      mediaWidth: 1071,
      eventContents: [
        {
          title: "2016 卒業制作",
          hero: {
            pc: require("@/assets/event/giftshow/hero/eye-sotugyo_pc.png"),
            sp: require("@/assets/event/giftshow/hero/eye-sotugyo_sp.png"),
          },
          images: [
            require("@/assets/event/giftshow/2016/2016sotsugyo_1-min.png"),
            require("@/assets/event/giftshow/2016/2016sotsugyo_2-min.png"),
            require("@/assets/event/giftshow/2016/2016sotsugyo_3-min.png"),
            require("@/assets/event/giftshow/2016/2016sotsugyo_4-min.png"),
            require("@/assets/event/giftshow/2016/2016sotsugyo_5-min.png"),
            require("@/assets/event/giftshow/2016/2016sotsugyo_6-min.png"),
            require("@/assets/event/giftshow/2016/2016sotsugyo_7-min.png"),
            require("@/assets/event/giftshow/2016/2016sotsugyo_8-min.png"),
            require("@/assets/event/giftshow/2016/2016sotsugyo_9-min.png"),
            require("@/assets/event/giftshow/2016/2016sotsugyo_10-min.png"),
            require("@/assets/event/giftshow/2016/2016sotsugyo_11-min.png"),
          ],
        },
        {
          title: "2017 Coffee Conet Tokyo",
          hero: {
            pc: require("@/assets/event/giftshow/hero/eye-cc_pc.png"),
            sp: require("@/assets/event/giftshow/hero/eye-cc_sp.png"),
          },
          images: [
            require("@/assets/event/giftshow/2017/coffee/coffee-1-min.png"),
            require("@/assets/event/giftshow/2017/coffee/coffee-2-min.png"),
            require("@/assets/event/giftshow/2017/coffee/coffee-3-min.png"),
            require("@/assets/event/giftshow/2017/coffee/coffee-4-min.png"),
            require("@/assets/event/giftshow/2017/coffee/coffee-5-min.png"),
          ],
        },
        {
          title: "2017 Design Festa",
          hero: {
            pc: require("@/assets/event/giftshow/hero/eye-df_pc.png"),
            sp: require("@/assets/event/giftshow/hero/eye-df_sp.png"),
          },
          images: [
            require("@/assets/event/giftshow/2017/design/designFesta-1-min.png"),
            require("@/assets/event/giftshow/2017/design/designFesta-2-min.png"),
            require("@/assets/event/giftshow/2017/design/designFesta-3-min.png"),
            require("@/assets/event/giftshow/2017/design/designFesta-4-min.png"),
            require("@/assets/event/giftshow/2017/design/designFesta-5-min.png"),
            require("@/assets/event/giftshow/2017/design/designFesta-6-min.png"),
            require("@/assets/event/giftshow/2017/design/designFesta-7-min.png"),
          ],
        },
        {
          title: "2018 LIVE PAINT",
          hero: {
            pc: require("@/assets/event/giftshow/hero/eye-live_pc.png"),
            sp: require("@/assets/event/giftshow/hero/eye-live_sp.png"),
          },
          images: [
            require("@/assets/event/giftshow/2018/anosky_1-min.png"),
            require("@/assets/event/giftshow/2018/anosky_2-min.png"),
          ],
        },
        {
          title: "2019 Mixed Medias",
          hero: {
            pc: require("@/assets/event/giftshow/hero/eye-mix_pc.png"),
            sp: require("@/assets/event/giftshow/hero/eye-mix_sp.png"),
          },
          images: [
            require("@/assets/event/giftshow/2019/2019_1-min.png"),
            require("@/assets/event/giftshow/2019/2019_2-min.png"),
            require("@/assets/event/giftshow/2019/2019_3-min.png"),
            require("@/assets/event/giftshow/2019/2019_4-min.png"),
            require("@/assets/event/giftshow/2019/2019_5-min.png"),
          ],
        },
        {
          title: "2020 IwataItoCana",
          hero: {
            pc: require("@/assets/event/giftshow/hero/eye-iwata_pc.png"),
            sp: require("@/assets/event/giftshow/hero/eye-iwata_sp.png"),
          },
          images: [
            require("@/assets/event/giftshow/2020/iwataitocana-1-min.png"),
            require("@/assets/event/giftshow/2020/iwataitocana-2-min.png"),
            require("@/assets/event/giftshow/2020/iwataitocana-3-min.png"),
            require("@/assets/event/giftshow/2020/iwataitocana-4-min.png"),
            require("@/assets/event/giftshow/2020/iwataitocana-5-min.png"),
            require("@/assets/event/giftshow/2020/iwataitocana-6-min.png"),
          ],
        },
        {
          title: "2021 1DK",
          hero: {
            pc: require("@/assets/event/giftshow/hero/eye-1dk_pc.png"),
            sp: require("@/assets/event/giftshow/hero/eye-1dk_sp.png"),
          },
          images: [
            require("@/assets/event/giftshow/2021/1DK-1-min.png"),
            require("@/assets/event/giftshow/2021/1DK-2-min.png"),
            require("@/assets/event/giftshow/2021/1DK-3-min.png"),
            require("@/assets/event/giftshow/2021/1DK-4-min.png"),
            require("@/assets/event/giftshow/2021/1DK-5-min.png"),
            require("@/assets/event/giftshow/2021/1DK-6-min.png"),
            require("@/assets/event/giftshow/2021/1DK-7-min.png"),
            require("@/assets/event/giftshow/2021/1DK-8-min.png"),
            require("@/assets/event/giftshow/2021/1DK-9-min.png"),
            require("@/assets/event/giftshow/2021/1DK-10-min.png"),
            require("@/assets/event/giftshow/2021/1DK-11-min.png"),
            require("@/assets/event/giftshow/2021/1DK-12-min.png"),
            require("@/assets/event/giftshow/2021/1DK-13-min.png"),
          ],
        },
        {
          title: "2022 Gift Show",
          hero: {
            pc: require("@/assets/event/giftshow/hero/eye-gift_pc.png"),
            sp: require("@/assets/event/giftshow/hero/eye-gift_sp.png"),
          },
          images: [
            require("@/assets/event/giftshow/2022/1.png"),
            require("@/assets/event/giftshow/2022/2.png"),
            require("@/assets/event/giftshow/2022/3.png"),
            require("@/assets/event/giftshow/2022/4.png"),
            require("@/assets/event/giftshow/2022/5.png"),
            require("@/assets/event/giftshow/2022/6.png"),
            require("@/assets/event/giftshow/2022/7.png"),
          ],
        },
      ],
    };
  },
});

const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
