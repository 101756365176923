<template>
  <div class="gift">
    <div class="head">
      <div class="head__title">
        <img class="head__title__icon" :src="icon" alt="icon" />
        <h2 class="head__title__text">{{ targetItem.title }}</h2>
      </div>
    </div>
    <div class="hero-area">
      <div class="hero">
        <img
          class="hero__img"
          :src="isMobile ? targetItem.hero.sp : targetItem.hero.pc"
          alt="hero image"
        />
      </div>

      <div class="index">
        <p class="index__text">
          <!-- text  -->
        </p>
      </div>

      <div class="divider"></div>
    </div>

    <div class="content">
      <div class="main">
        <div v-for="img in targetItem.images" class="card" :key="img">
          <img class="tile" :src="img" alt="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      icon: require("@/assets/event/cake.png"),
      items: this.$store.state.eventContents,
    };
  },
  computed: {
    paramsId() {
      return this.$route.params.id;
    },
    reverseItem() {
      return this.items.slice().reverse();
    },
    targetItem() {
      return this.reverseItem[this.$route.params.id];
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile =
        window.innerWidth <= this.$store.state.mediaWidth ? true : false;
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>

<style scoped>
/* PC */
@media (min-width: 1071px) {
  .gift {
    position: relative;
    min-height: 100vh;
    width: calc(100% - 256px);
  }

  .head {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .head__title {
    display: flex;
    align-items: center;
    margin: 8px 24px;
  }
  .head__title__icon {
    width: 40px;
    height: 40px;
    margin: 0 24px 0 0;
  }
  .head__title__text {
    color: #333;
    padding: 0;
    margin: 0;
    font-size: 22px;
    letter-spacing: 0px;
    font-family: futura;
    text-align: center;
    font-weight: 600;
  }

  .hero {
    width: 100%;
  }
  .hero__img {
    width: 100%;
  }

  .divider {
    width: 100%;
    border-bottom: dashed 4px #ccc;
    margin: 40px 0;
  }

  .index {
    text-align: center;
  }
  .index__text {
    font-weight: bold;
    font-size: 16px;
    font-family: futura;
  }

  .content {
    margin: 40px 0 0 0;
    background-color: #fff;
  }
}

/* SP */
@media (max-width: 1070px) {
  .gift {
    position: relative;
    min-height: 100vh;
  }
  .head {
    width: 100%;
  }
  .head__title {
    display: flex;
    align-items: center;
    padding: 8px;
  }
  .head__title__icon {
    width: 32px;
    height: 32px;
    margin: 0 16px 0 0;
  }
  .head__title__text {
    color: #333;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: futura;
    font-weight: 600;
  }

  .hero-area {
    width: 90%;
    margin: 24px auto 0 auto;
  }
  .hero {
    margin: 12px 0 0 0;
  }
  .hero__img {
    width: 100%;
  }

  .divider {
    width: 100%;
    border-bottom: dashed 4px #ccc;
    margin: 40px 0;
  }

  .index {
    text-align: center;
  }
  .index__text {
    font-weight: bold;
    font-size: 10px;
    font-family: futura;
  }

  .content {
    width: 100vw;
    background-color: #fff;
    margin: 0 0 24px 0;
  }
}

.main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #fff;
}
.card {
  margin: 4px;
  width: 45%;
}

.tile {
  width: 100%;
}
</style>
