<template>
  <div class="head">
    <img class="head__img" :src="icon" alt="title icon" />
    <h2 class="head__title">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
@media (min-width: 1071px) {
  .head {
    display: flex;
    align-items: center;
    height: 56px;
    width: 100%;
  }
  .head__img {
    width: 32px;
    height: 32px;
    margin: 8px;
  }
  .head__title {
    color: #333;
    padding: 0;
    margin: 0;
    font-size: 32px;
    font-family: futura;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: -1px;
  }
}

@media (max-width: 1070px) {
  .head {
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    background-color: #fff;
  }
  .head__img {
    width: 24px;
    height: 24px;
    margin: 8px;
  }
  .head__title {
    color: #333;
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-family: futura;
    font-weight: 900;
    letter-spacing: -1px;
    text-transform: uppercase;
  }
}
</style>
